import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const DynamicEntityTitle = ({ blok }) => {
  const {
    collection,
    product,
    level = 3,
    sizeAsHeadingLevel,
    entityType,
  } = blok;
  const source = entityType === 'collection' ? collection : product;
  const adjustedStyle = useAdjustedHeadingSize(sizeAsHeadingLevel);
  return (
    <Heading {...storyblokEditable(blok)} level={level} style={adjustedStyle}>
      {source?.title}
    </Heading>
  );
};

DynamicEntityTitle.propTypes = {
  blok: PropTypes.shape({
    collection: PropTypes.object,
    product: PropTypes.object,
    entityType: PropTypes.oneOf(['collection', 'product']),
    level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, '1', '2', '3', '4', '5', '6']),
  }),
};

export default DynamicEntityTitle;
